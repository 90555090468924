import React from "react"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { customStylesHomePage } from "../styles/customSelectStyles"

const animatedComponents = makeAnimated()

const TagSearch = ({ selectTag, tags }) => {
  return (
    <Select
      closeMenuOnSelect={false}
      isClearable={false}
      onChange={e => {
        if (e === null) {
          return selectTag([])
        }

        return selectTag(e)
      }}
      components={animatedComponents}
      isMulti
      options={tags}
      styles={customStylesHomePage}
      placeholder={"Search by tags..."}
      noOptionsMessage={() => "Tag not found"}
    />
  )
}

export default TagSearch
